import * as React from 'react';
import { useContext, createContext, FC, useState, useEffect } from 'react';
import { useApi } from './Api';
import Script from 'react-load-script';

declare var Stripe: stripe.StripeStatic;

export const LOCALSTORAGE_KEY = 'StripeApiKey';

const storedApiKey = localStorage.getItem(LOCALSTORAGE_KEY) || undefined;
type StripeScript = {

    loading?: boolean;
    key?: string;
    stripe?: stripe.Stripe;
};

export const StripeScriptContext = createContext<StripeScript>({} as never);

export const StripeScriptProvider: FC = ({ children }) => {
    const [key, setKey] = useState(storedApiKey);
    const [loading, setLoading] = useState(false);
    const [stripeLoaded, setStripeLoaded] = useState(false);
    const [stripe, setStripe] = useState<stripe.Stripe>();
    const { fetch } = useApi();

    useEffect(
        () => {
            const effect = async () => {
                if (!key) {
                    setLoading(true);
                    const apikey = await fetch<string>('billing/stripe', { method: 'GET' });
                    setKey(apikey);
                    setLoading(false);
                }
            };
            effect();
        },
        []
    );

    useEffect(
        () => {
            if (stripeLoaded && key) {
                setStripe(Stripe(key));
            }
        },
        [stripeLoaded, key]
    );

    const onLoadStripe = () => {
        setStripeLoaded(true);

    };
    const onLoadErrorStripe = () => {
        setStripeLoaded(false);
    };

    return (
        <StripeScriptContext.Provider value={{ key, stripe, loading }}>
            <Script
                url="https://js.stripe.com/v3"
                onLoad={onLoadStripe}
                onError={onLoadErrorStripe}
            />
            {children}
        </StripeScriptContext.Provider>
    );
};

export const useStripeScript = () => useContext(StripeScriptContext);
