import * as React from 'react';

import {
	makeStyles,
	createStyles,
	Theme,
	Typography,
	Paper,
} from '@material-ui/core';
import { useApi } from '../@context/Api';
import { FC, useState } from 'react';
import { GetQueryVariable } from '../@utils/url';

import PayByPaypal from '../components/PayByPaypal';
import PayByCard from '../components/PayByCard';
import { underline } from '../constants/stylesheet';
import classNames from 'classnames';

import PayByPaymentRequest from 'components/PayByPaymentRequest';
import { StripeScriptProvider } from '@context/StripeScript';
import PayByTransfer from 'components/PayByTransfer';
import PayByPostFinance from 'components/PayByPostFinance';
import {
	AssignmentTurnedIn,
	AssignmentLate,
	Assignment,
} from '@material-ui/icons';

const rotate = {
	from: {
		transformOrigin: 'center',
		transform: `translate3d(-50%,-50%,0) rotate3d(0, 0, 1, 0)`,
	},
	to: {
		transformOrigin: 'center',
		transform: `translate3d(-50%,-50%,0) rotate3d(0, 0, 1, 360deg)`,
	},
};

const size = 23;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: 'relative',
			width: '100%',
			height: '100%',
			overflow: 'auto',
			padding: theme.spacing(3),
			boxSizing: 'border-box',
		},
		loadingElement: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			width: size,
			height: size,
			borderRadius: 1000,
			border: '1px solid black',
			background: 'transparent',
			animationName: '$rotate',
			animationIterationCount: 'infinite',
			animationDuration: '1s',
			animationTimingFunction: 'linear',
			borderTopColor: 'transparent',
		},
		content: {
			maxWidth: '800px',
		},
		pageTitle: {
			display: 'flex',
			alignItems: 'center',
			marginRight: theme.spacing(2),
			marginBottom: theme.spacing(3),
		},
		text: {
			marginBottom: theme.spacing(2),
		},
		paper: {
			margin: theme.spacing(1, 0),
			padding: theme.spacing(2),
			borderRadius: 0,
		},

		linkText: {
			position: 'relative',
			cursor: 'pointer',
			'&:hover:after': underline,
		},
		active: {
			'&$linkText:after': underline,
		},
		bold: {
			fontWeight: 500,
		},

		'@keyframes rotate': rotate,
		loading: {
			fill: theme.palette.secondary.main,
		},
		paid: {
			fill: theme.palette.success.main,
		},
		due: {
			fill: theme.palette.primary.main,
		},
		overDue: {
			fill: theme.palette.error.main,
		},
	})
);

const invoiceTextTranslation = {
	en: 'Invoice',
	de: 'Rechnung',
	fr: 'Facture',
	pt: 'Fatura',
};

const choosePaymentMethodTranslation = {
	en: 'Please choose your payment method:',
	de: 'Bitte wählen Sie Ihre Zahlungsart:',
	fr: 'Veuillez choisir votre mode de paiement:',
	pt: 'Por favor, escolha o seu método de pagamento:',
};

const greetingTextTranslation = {
	en: 'Thanks!',
	de: 'Danke!',
	fr: 'Merci!',
	pt: 'Obrigado!',
};

const PayPage: FC = ({ children }) => {
	const classes = useStyles({});
	const { fetch } = useApi();
	const id = GetQueryVariable('id');

	const [invoice, setInvoice] = useState<InvoiceToPay>();
	const [option, setOption] = useState('');

	React.useEffect(() => {
		if (!invoice) {
			fetch<InvoiceToPay>(`billing/invoice/${id}/payments`, {
				method: 'GET',
			}).then((i) => {
				setInvoice(i);
			});
		}
	}, [fetch, id, invoice]);

	const refresh = () => {
		fetch<InvoiceToPay>(`billing/invoice/${id}/payments`, {
			method: 'GET',
		}).then((i) => {
			setInvoice(i);
		});
	};

	const invoiceLanguage = (invoice && invoice.InvoiceLanguage) || 'EN';

	return (
		<div className={classes.root}>
			{!invoice && <div className={classes!.loadingElement} />}
			{invoice && (
				<div className={classes.content}>
					<Typography variant='h1' className={classes!.pageTitle}>
						{invoice.State === 'Paid' && (
							<AssignmentTurnedIn className={classes.paid} />
						)}
						{invoice.State === 'Due' && (
							<AssignmentLate className={classes.due} />
						)}
						{invoice.State === 'OverDue' && (
							<Assignment className={classes.overDue} />
						)}
						<span>{`${invoiceTextTranslation[invoiceLanguage]}: #${
							invoice && invoice.Reference
						}`}</span>
					</Typography>
					<div className={classes.text}>
						<Typography variant='h5'>
							{invoice.State !== 'Paid'
								? `${choosePaymentMethodTranslation[invoiceLanguage]}`
								: `${greetingTextTranslation[invoiceLanguage]}`}
						</Typography>
					</div>
					<StripeScriptProvider>
						{invoice && invoice.State !== 'Paid' && invoice.AllowInstant && (
							<>
								<Typography onClick={() => setOption('instant')}>
									<span
										className={classNames(
											classes.linkText,
											option === 'instant' && classes.active
										)}
									>
										Instant pay
									</span>
								</Typography>
								{option === 'instant' && (
									<Paper className={classes.paper} elevation={0}>
										<PayByPaymentRequest invoice={invoice} done={refresh} />
									</Paper>
								)}
							</>
						)}
						{invoice && invoice.State !== 'Paid' && invoice.AllowStripe && (
							<>
								<Typography onClick={() => setOption('card')}>
									<span
										className={classNames(
											classes.linkText,
											option === 'card' && classes.active
										)}
									>
										Credit or debit card
									</span>
								</Typography>
								{option === 'card' && (
									<Paper className={classes.paper} elevation={0}>
										<PayByCard invoice={invoice} done={refresh} />
									</Paper>
								)}
							</>
						)}
						{invoice && invoice.State !== 'Paid' && invoice.AllowPostFinance && (
							<>
								<Typography onClick={() => setOption('postfinance')}>
									<span
										className={classNames(
											classes.linkText,
											option === 'postfinance' && classes.active
										)}
									>
										PostFinance
									</span>
								</Typography>
								{option === 'postfinance' && (
									<div className={classes.paper}>
										<PayByPostFinance invoice={invoice} done={refresh} />
									</div>
								)}
							</>
						)}
						{invoice && invoice.State !== 'Paid' && invoice.AllowPaypal && (
							<>
								<Typography onClick={() => setOption('paypal')}>
									<span
										className={classNames(
											classes.linkText,
											option === 'paypal' && classes.active
										)}
									>
										PayPal
									</span>
								</Typography>
								{option === 'paypal' && (
									<div className={classes.paper}>
										<PayByPaypal invoice={invoice} done={refresh} />
									</div>
								)}
							</>
						)}
						{invoice && invoice.State !== 'Paid' && invoice.AllowTransfer && (
							<>
								<Typography onClick={() => setOption('transfer')}>
									<span
										className={classNames(
											classes.linkText,
											option === 'transfer' && classes.active
										)}
									>
										Transfer
									</span>
								</Typography>
								{option === 'transfer' && (
									<Paper className={classes.paper} elevation={0}>
										<PayByTransfer invoice={invoice} done={refresh} />
									</Paper>
								)}
							</>
						)}
					</StripeScriptProvider>
				</div>
			)}
			{children}
		</div>
	);
};

export default PayPage;
