import React, { FC, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useStripeScript } from '@context/StripeScript';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {},

  error: {
    fontSize: '0.8rem',
    color: 'red',
    marginTop: '1rem',
  },
  info: {
    fontSize: '0.8rem',
    color: '#b7b7b7',
    marginTop: '1rem',
  },
  label: {},
});

const PayByPaymentRequest: FC<{ invoice: InvoiceToPay; done: () => void }> = (
  props
) => {
  const cls = useStyles({});
  const { invoice, done } = props;
  const { stripe } = useStripeScript();
  const paymentRequestOptions = {
    country: invoice.CountryIso,
    currency: invoice.Currency.toLocaleLowerCase(),
    total: {
      label: 'total',
      amount: (invoice.Amount - invoice.PaidAmount) * 100,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  };
  const prButtonRef = useRef<HTMLDivElement>(null);
  const [prButton, setPrButton] = useState<stripe.elements.Element>();
  const [error, setError] = useState<string>();
  const [info, setInfo] = useState<string>();

  useEffect(() => {
    const effect = async () => {
      if (!stripe) {
        return;
      }
      const paymentRequest = stripe.paymentRequest(paymentRequestOptions);
      const canMakePayment = await paymentRequest.canMakePayment();
      if (!canMakePayment) {
        // tslint:disable-next-line: max-line-length
        const explanation =
          'Apple Pay, Google Pay and Microsoft Pay need the Safari, Chrome or Ms Edge with payments configured to pay instantly';
        setInfo(explanation);
        return;
      }

      const elements = stripe.elements();
      const currentPrButton = elements.create('paymentRequestButton', {
        paymentRequest,
      });
      setPrButton(currentPrButton);

      let clientSecret = '';
      paymentRequest.on('source', ({ source: { client_secret } }) => {
        clientSecret = client_secret;
      });
      paymentRequest.on(
        'paymentmethod',
        async ({ paymentMethod: { id }, complete }) => {
          const { error: IntentError } = await stripe.confirmPaymentIntent(
            clientSecret,
            {
              payment_method: id,
            }
          );

          if (IntentError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            complete('fail');

            setError(`The payment failed (${IntentError.message || ''})`);
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            complete('success');

            // Let Stripe.js handle the rest of the payment flow.
            const cardPayment = await stripe.handleCardPayment(clientSecret);
            if (cardPayment.error) {
              // The payment failed -- ask your customer for a new payment method.
              setError(
                `The payment failed, please try a new payment method (${
                  cardPayment.error.message || ''
                })`
              );
            } else {
              done();
              // The payment has succeeded.
            }
          }
        }
      );
      // paymentRequest.show();
    };
    effect();
  }, [stripe]);

  useEffect(() => {
    if (prButton && prButtonRef.current) {
      prButton.mount(prButtonRef.current);
    }
  }, [prButton, prButtonRef]);
  return (
    <>
      {prButton && <div ref={prButtonRef} />}

      {info &&
        info.split('\n').map((l, i) => (
          <Typography
            color='textSecondary'
            key={i}
            className={classNames(cls.info)}
          >
            {l}
          </Typography>
        ))}
      {error &&
        error.split('\n').map((l, i) => (
          <Typography color='error' key={i} className={classNames(cls.error)}>
            {l}
          </Typography>
        ))}
    </>
  );
};
export default PayByPaymentRequest;
