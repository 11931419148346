import React, { FC, useState, useEffect, useRef, FormEvent } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';
import { useApi } from '../@context/Api';
import { useStripeScript } from '@context/StripeScript';
import { Button, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .StripeElement': {
        flex: '1 1 auto',
        boxSizing: 'border-box',
        height: '40px',
        padding: '10px 12px',
        border: '1px solid transparent',
        borderRadius: '4px',
        backgroundColor: 'white',
        width: '100%',
        marginBottom: '1rem',
        boxShadow: '0 1px 3px 0 #e6ebf1',

        transition: 'box-shadow 150ms ease',
        '&--focus': {
          boxShadow: '0 1px 3px 0 #cfd7df',
        },
        '&--invalid': {
          borderColor: '#fa755a',
        },
        '&--webkit-autofill': {
          backgroundColor: '#fefde5 !important',
        },
      },
    },
    fields: {},
    error: {
      fontSize: '0.8rem',
      color: 'red',
      marginTop: '1rem',
    },
    label: {},
    button: {
      flex: '1 1 auto',

      display: 'flex',
      justifyContent: 'center',
      background: theme.palette.primary.main,
      color: 'white',

      '&:hover': {
        background: theme.palette.primary.dark,
      },
    },
    buttonProcessing: {
      pointerEvents: 'none',
      boxShadow: '0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08)',
      background: theme.palette.success.main,
      color: 'white',

      '&:hover': {
        background: theme.palette.success.dark,
      },
    },
    progress: {
      width: '1rem',
      height: '1rem',
      marginRight: '0.5rem',
      color: 'white',
    },
  }));

const cardStyle = {
  base: {
    fontFamily: 'circular',
    color: '#32325d',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

const PayByCard: FC<{ invoice: InvoiceToPay; done: () => void }> = ({
  invoice,
  done,
}) => {
  const cls = useStyles();

  const { stripe } = useStripeScript();
  const cardElementsRef = useRef<HTMLDivElement>(null);
  const [cardElements, setCardElements] = useState<stripe.elements.Element>();
  const [error, setError] = useState<string>();
  const [processing, setProcessing] = useState(false);
  const { fetch } = useApi();

  useEffect(() => {
    if (stripe) {
      const elements = stripe.elements({
        fonts: [{ cssSrc: '/assets/fonts.css' }],
      });
      const card = elements.create('card', { style: cardStyle });
      setCardElements(card);
    }
  }, [invoice, stripe]);

  useEffect(() => {
    if (cardElements && cardElementsRef.current) {
      cardElements.mount(cardElementsRef.current);
      // Handle real-time validation errors from the card Element.
    }
  }, [cardElements, cardElementsRef]);

  const onSubmitForm = async (ev: FormEvent) => {
    ev.preventDefault();

    if (!stripe || !cardElements || !invoice) {
      return;
    }
    setProcessing(true);
    const { token, error: TokenError } = await stripe.createToken(cardElements);
    if (TokenError) {
      setProcessing(false);
      setError(TokenError.message);
      return;
    } else if (token) {
      try {
        await fetch<string>('billing/stripe', {
          method: 'POST',
          body: JSON.stringify({
            InvoiceId: invoice.Id,
            Amount: invoice.Amount - invoice.PaidAmount,
            Token: token.id,
          }),
        });
        setProcessing(false);
        done();
      } catch {
        setProcessing(false);
        setError(
          'there has been a problem validating the payment, please contact us'
        );
      }
    }
  };

  return (
    <>
      {cardElements && (
        <form onSubmit={onSubmitForm} className={cls.root}>
          <div className={cls.fields}>
            <div ref={cardElementsRef} />
            <Button
              className={classNames(
                cls.button,
                processing && cls.buttonProcessing
              )}
              type='submit'
              color='primary'
            >
              {processing ? (
                <>
                  <CircularProgress size='1rem' className={cls.progress} />{' '}
                  Processing...
                </>
              ) : (
                  'Submit Payment'
                )}
            </Button>
          </div>
        </form>
      )}
      {error &&
        error.split('\n').map((l, i) => (
          <div key={i} className={cls.error}>
            {l}
          </div>
        ))}
    </>
  );
};
export default PayByCard;
